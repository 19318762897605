<template>
  <div style="overflow: hidden">
    <Form class="padding-10 pl-20" :model="detail" :label-width="80" label-position="left">
      <FormItem label="学员姓名">
        <span>{{detail.name}}</span>
      </FormItem>
      <FormItem label="身份证号">
        <span>{{detail.carId}}</span>
      </FormItem>
      <FormItem label="班次名称">
        <span>{{ detail.classes }}</span>
      </FormItem>
      <FormItem label="培训类型">
        <span>{{detail.type}}</span>
      </FormItem>
      <FormItem label="培训方式">
        <span>线上培训</span>
      </FormItem>
      <FormItem label="培训机构">
        <span>江苏安胜达安全科技有限公司</span>
      </FormItem>
      <FormItem label="培训时间">
        <span>{{$moment(detail.startDate).format('yyyy年MM月DD日')}}-{{$moment(detail.endDate).format('yyyy年MM月DD日')}}</span>
      </FormItem>
      <FormItem label="应修学时">
        <span>{{detail.onlineTime}}</span>
      </FormItem>
      <FormItem label="已修学时">
        <span>{{detail.finishTime}}</span>
      </FormItem>
    </Form>
    <div style="background-color:#f5f5f5;height: 10px;width: 100%;"></div>
    <div>
      <p class="title">
        学时明细
      </p>
      <Collapse accordion simple>
        <Panel v-for="(item, index) in list" :key="index" :name="String(index)">
          {{ item.title }}
          <div slot="content">
            <div style="color:#969799;display: flex;align-items: center;justify-content: space-between;margin-left: 4px;padding: 5px 0;" v-for="(_item, _index) in item.children" :key="_index">
              <p style="width: 70%;">{{_item.title}}</p>
              <span>{{_item.time}}/{{_item.time}}</span>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Index',
  components: {},
  props: {},
  data () {
    return {
      detail: {},
      list: []
    }
  },
  filters: {},
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
    window.document.title = '学习明细'
  },
  created () {
    axios.get(`https://peixun.asdsafe.cn:18001/onlineStudent/info?userId=${this.$route.query.userId}`).then(res => {
      if (res.data.code === 200) {
        this.detail = res.data.data
        console.log(this.detail)
        if (this.detail.type === '初训') {
          this.list = [
            {
              title: '第一章 安全生产责任制',
              children: [
                {
                  title: '1.1 企业主要负责人安全生产职责',
                  time: 0.18
                },
                {
                  title: '1.2 企业主要负责人安全生产职责',
                  time: 0.11
                },
                {
                  title: '1.3 企业安全管理机构和安全管理人员职责释义',
                  time: 0.08
                },
                {
                  title: '1.4 企业安全管理机构和安全管理人员职责释义',
                  time: 0.12
                },
                {
                  title: '1.5 企业安全生产主体责任缺失警示录',
                  time: 0.38
                },
                {
                  title: '1.6 全员安全生产责任制',
                  time: 2.01
                }
              ]
            },
            {
              title: '第二章 安全生产形势及事故警示',
              children: [
                {
                  title: '2.1 生命重于泰山',
                  time: 0.83
                },
                {
                  title: '2.2 事故案例1连云港“12.9”重大爆炸事故',
                  time: 0.15
                },
                {
                  title: '2.3 事故案例2义马气化厂爆炸事故',
                  time: 0.28
                },
                {
                  title: '2.4 事故案例3有限空间作业中毒事故',
                  time: 0.11
                },
                {
                  title: '2.5 违章作业的代价',
                  time: 0.17
                },
                {
                  title: '2.6 让悲剧不再重演',
                  time: 0.40
                }
              ]
            },
            {
              title: '第三章 安全生产管理体系构建',
              children: [
                {
                  title: '3.1 企业安全风险管控体系构建要点',
                  time: 1.86
                },
                {
                  title: '3.2 风险管理和隐患排查双重预防体系',
                  time: 0.56
                }
              ]
            },
            {
              title: '第四章 安全生产管理知识',
              children: [
                {
                  title: '4.1 安全风险管控与事故隐患排查治理专项',
                  time: 1.38
                },
                {
                  title: '4.2 安全风险管控与事故隐患排查治理专项',
                  time: 1.65
                }
              ]
            },
            {
              title: '第五章 现场安全管理',
              children: [
                {
                  title: '5.1 现场隐患排查要点',
                  time: 0.57
                },
                {
                  title: '5.2 现场隐患排查要点',
                  time: 0.53
                },
                {
                  title: '5.3 三级安全培训',
                  time: 0.72
                },
                {
                  title: '5.4 危险作业风险剖析与管理',
                  time: 0.72
                },
                {
                  title: '5.5 有限空间作业安全',
                  time: 0.20
                },
                {
                  title: '5.6 机械设备风险分析与操作安全',
                  time: 0.40
                },
                {
                  title: '5.7 消防安全',
                  time: 0.58
                },
                {
                  title: '5.8 安全行为观察',
                  time: 1.28
                }
              ]
            },
            {
              title: '第六章 事故案例专项培训',
              children: [
                {
                  title: '6.1 事故案例专项培训',
                  time: 0.89
                }
              ]
            },
            {
              title: '第七章 现场急救培训',
              children: [
                {
                  title: '7.1 心肺复苏培训',
                  time: 0.08
                },
                {
                  title: '7.2 触电急救',
                  time: 0.03
                }
              ]
            }
          ]
        } else if (this.detail.type === '工伤预防') {
          this.list = [
            {
              title: '第一章 气瓶安全管理',
              children: [
                {
                  title: '1.1 气瓶安全管理',
                  time: 1.5
                }
              ]
            },
            {
              title: '第二章 风险辨识于分级',
              children: [
                {
                  title: '2.1 风险辨识于分级',
                  time: 2
                }
              ]
            },
            {
              title: '第三章 工伤预防通用课程培训',
              children: [
                {
                  title: '3.1 工伤预防通用课程培训',
                  time: 1.5
                }
              ]
            },
            {
              title: '第四章 机械安全培训',
              children: [
                {
                  title: '4.1 机械安全培训',
                  time: 2
                }
              ]
            },
            {
              title: '第五章 工伤预防与法律法规',
              children: [
                {
                  title: '5.1 工伤预防与法律法规',
                  time: 2
                }
              ]
            },
            {
              title: '第六章 配电室安全管理培训',
              children: [
                {
                  title: '5.1 配电室安全管理培训',
                  time: 1
                }
              ]
            },
            {
              title: '第七章 消防安全技术及管理培训',
              children: [
                {
                  title: '7.1 消防安全技术及管理培训',
                  time: 1
                }
              ]
            },
            {
              title: '第八章 职业危害及职业病防治培训',
              children: [
                {
                  title: '8.1 职业危害及职业病防治培训',
                  time: 1
                }
              ]
            }
          ]
        } else {
          this.list = [
            {
              title: '第一章 安全生产形势及事故警示',
              children: [
                {
                  title: '1.1 生命重于泰山',
                  time: 0.83
                },
                {
                  title: '1.2 事故案例1连云港“12.9”重大爆炸事故',
                  time: 0.15
                },
                {
                  title: '1.3 事故案例2义马气化厂爆炸事故',
                  time: 0.28
                },
                {
                  title: '1.4 事故案例3有限空间作业中毒事故',
                  time: 0.11
                },
                {
                  title: '1.5 违章作业的代价',
                  time: 0.17
                },
                {
                  title: '1.6 让悲剧不再重演',
                  time: 0.40
                }
              ]
            },
            {
              title: '第二章 安全生产管理体系构建',
              children: [
                {
                  title: '2.1 企业安全风险管控体系构建要点',
                  time: 1.86
                },
                {
                  title: '2.2 风险管理和隐患排查双重预防体系',
                  time: 0.56
                }
              ]
            }
          ]
        }
      }
    })
  },
  beforeMount () {
  },
  mounted () {

  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
/deep/.ivu-form-item {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  .ivu-form-item-label {
    font-size: 16px;
  }
  .ivu-form-item-content {
    font-size: 16px;
    margin-left: 0!important;
    flex: 1;
  }
}
.title {
  position: relative;
  left: 12px;
  padding: 14px 20px;
  &:before{
    content: ' ';
    position: absolute;
    top: 15px;
    left: 8px;
    width: 4px;
    height: 20px;
    background: #1990ff;
  }
}
/deep/.ivu-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  color: #333333!important;
  height: 50px!important;
  padding-left: 20px!important;
}
</style>
